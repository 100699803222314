import React, { memo, useMemo } from 'react';

import ListaCheckbox from './ListaCheckbox/index';
import ListaRadio  from './ListaRadio/index';
import CampoTexto from './CampoTexto';

import { RespostaContainer } from './styles';

const Resposta = ({
  tipo,
  alternativas,
  resposta,
  salvarAvaliacao,
  avaliadoAtual,
  sessao,
 }) => {

  const renderizaTipoRespota = useMemo(() => {
    switch(tipo) {
      case 'TEXT':
        return (
          <CampoTexto
            valorInicial={resposta && resposta[0].descricao}
            resposta={resposta && resposta}
            onChange={salvarAvaliacao}
            ativo={sessao[0].ativo}
          />
        )
      case 'CHECKBOX':
        return (
          <ListaCheckbox
            items={alternativas}
            valorInicial={resposta[0] && resposta[0].id_alternativa}
            propriedadeValor='id_alternativa'
            propriedadeLabel='alternativa'
            propriedadeDica='dica'
            onChange={salvarAvaliacao}
            avaliadoAtual={avaliadoAtual}
            ativo={sessao[0].ativo}
          />
        );
      case 'RADIO':
        return (
          <ListaRadio
            items={alternativas}
            valorInicial={resposta[0] && resposta[0].id_alternativa}
            resposta={resposta && resposta}
            propriedadeValor='id_alternativa'
            propriedadeLabel='alternativa'
            propriedadeDica='dica'
            onChange={salvarAvaliacao}
            ativo={sessao[0].ativo}
          />
        );
      default:
        return <CampoTexto resposta={resposta} />
    }
  }, [alternativas, resposta, salvarAvaliacao, tipo, avaliadoAtual, sessao])

  return (
    <RespostaContainer>
      {renderizaTipoRespota}
    </RespostaContainer>
  );
}

export default memo(Resposta)
