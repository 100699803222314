import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import RotaPrivada from './componentes/RotaPrivada';
import Avaliacao from './paginas/Avaliacao';
import Login from './paginas/Login';
import Sessao from './paginas/Sessao';
import Formulario from './paginas/Formulario';

const Rotas = () => {
  return (
    <BrowserRouter>
      <Switch>
        {/* Default route redirect Libcom */}
        <Route exact path="/">
            <Redirect to="/libcom" />
        </Route>
        <Route path="/:schema" exact component={Login} />
        <RotaPrivada path="/:schema/formularios" exact component={Formulario} />
        <RotaPrivada path="/:schema/formularios/:id_formulario/sessoes" exact component={Sessao} /> 
        <RotaPrivada path="/:schema/formularios/:id_formulario/sessoes/:id_sessao/:avaliacoes" exact component={Avaliacao} />
      </Switch>
    </BrowserRouter>
  )
}

export default Rotas;