import styled from 'styled-components';

import { Link } from 'react-router-dom';
import { Container } from '@material-ui/core';


export const MuiContainer = styled(Container)`
  && {
    margin-top: 80px;
    max-width: 600px;
  }
`;

export const ButtonGroup = styled.div`
    display: block;

    position: fixed;
    left: 0;
    bottom: 0;

    width: 100%;
    z-index: 9;

    button, a {
        padding: var(--espacamento-extra-pequeno) var(--espacamento-medio);

        font-weight: 600;

        width: 100%;

        box-shadow: none;

        &[disabled] {
          opacity: 0.7;
        }
    }

    .MuiButton-outlined {
        background: var(--cor-branco);
    }
`;

export const LinkButton = styled(Link)`
    && {
        display: flex;
        justify-content: center;

        background: var(--cor-primaria);
        color: var(--cor-secondaria);
        font-weight: 500;
        font-family: 'Roboto';
        text-decoration: none;
        text-transform: uppercase;

        padding: 14px;
    }

    &[disabled] {
        background: var(--cor-fundo-dark-3);
        pointer-events: none;
        color: var(--cor-texto-dark-1);
    }
`;
