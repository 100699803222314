export function irParaNovaRota(history, rota = '') {
  history.push(`/${rota}`);
}

export function mascaraCPF (cpf) {
  return cpf
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export function formatarDataParaIso(data) {
  if(!data) {
    return
  }
  return data.toISOString().slice(0,10)
}

export function scrollToX(element, to, duration) {
  var start = element.scrollLeft,
  change = to - start,
  currentTime = 0,
  increment = 20;

  var animateScroll = function(){
    currentTime += increment;
    var val = Math.easeInOutQuad(currentTime, start, change, duration);
      element.scrollLeft = val;
      if(currentTime < duration) {
          setTimeout(animateScroll, increment);
        }
      };
  animateScroll();
}
//t = current time
//b = start value
//c = change in value
//d = duration
Math.easeInOutQuad = function (t, b, c, d) {
t /= d/2;
  if (t < 1) return c/2*t*t + b;
  t--;
  return -c/2 * (t*(t-2) - 1) + b;
};

export function getAmbiente() {
let enviroment

let hostname = window.location.hostname; // it can set string for test
  if (hostname.match(/-develop/g))
    enviroment = 'develop';
  else if (hostname.match(/-release/g))
    enviroment = 'release';
  else if (hostname.match(/-production/g) || hostname.match(/libcom.org.br/g))
    enviroment = 'production';
  else if (hostname.match(/-hotfix/g))
    enviroment = 'hotfix';
  else if (hostname.match(/localhost/g))
    enviroment = 'localhost';

  return enviroment;
}


export function getSchema() {
  let schema;

  // verifica se é o schema Reinaldo
  const currentUrl = window.location.pathname.split('/');
  const schemaReinaldo = currentUrl[1];

  if(schemaReinaldo === 'z00001ra')
    schema = `z00001ra`;
  else
    schema = `libcom`;

  return schema;
}

export function getApiUrl() {
  let apiUrl, schema, enviroment;

  schema = getSchema();
  enviroment = getAmbiente();

  if(enviroment === 'develop' || enviroment === 'localhost')
    apiUrl = `https://api-sima-develop.rj.r.appspot.com/${schema}`;
  else if(enviroment === 'release' || enviroment === 'hotfix')
    apiUrl = `https://api-sima-release-287513.rj.r.appspot.com/${schema}`;
  else if(enviroment === 'production')
    apiUrl = `https://api-sima-production.rj.r.appspot.com/${schema}`;

  console.log(`ambiente: ${enviroment} \nschema: ${schema} \napi-url: ${apiUrl}`);
  return apiUrl;
}
