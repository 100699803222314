import axios from 'axios';
import { getToken } from './autenticacao';
import { getApiUrl } from '../util/ferramentas';

const api = axios.create({
  baseURL: getApiUrl(),
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'DELETE, POST, PUT, GET, OPTIONS',
    'Access-Control-Max-Age': 86400
  }
});

api.interceptors.request.use(config => {
  const token = getToken();
  if (token) {
    config.headers['x-access-token'] = token;
  }
  
  return config;
});

const requisicao = async (url, metodo, corpo) => {
  try {
    const response = await api({
      method: metodo,
      url: url,
      data: corpo ? JSON.stringify(corpo) : null
    });

    return response.data;
  }
  catch(err) {
    if(err.response.status >= 400 && err.response.status <= 500) throw err.response.data;
  }
} 

// Métodos HTTP
export const get = (url) => requisicao(url, 'get');
export const post = (url, corpo) => requisicao(url, 'post', corpo);
export const put = (url, corpo) => requisicao(url, 'put', corpo);