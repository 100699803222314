import styled from "styled-components";

import LinearProgress from '@material-ui/core/LinearProgress';

import '../../util/cssGlobal/configuracao/cores.css';


export const BarraProgresso = styled(LinearProgress)`
  &&{
    height: 8px;
    border-radius: 4px;
    margin-top: 12px;

    background: var(--cor-fundo-cinza);
    box-shadow: inset 0px 0px 3px rgba(0,0,0,0.2);
    .MuiLinearProgress-bar1Determinate {
      background: ${(props) => props.value >= 100 ? 'var(--cor-verde-300)' : 'var(--cor-primaria)'};
      box-shadow: inset 0px 0px 3px rgba(0,0,0,0.2);
    }
  }
`;

export const Porcentagem = styled.span`
  && {
    color: ${(props) => props.value >= 100 ? 'var(--cor-verde-300)' : 'var(--cor-primaria)'};
    color: ${(props) => props.value === 0 && 'var(--cor-secundaria)'};

    font-size: 10px;
    ${(props) => props.value > 0 && `
      font-weight: 600;
    `};
  }
`;


export const Cards = styled.ul`
  /* outline: 1px solid red; */

  display: flex;
  flex-direction: column;
  padding: var(--espacamento-medio);
  max-width: 550px;
  margin: 42px auto 0px;
`;

export const CardItem = styled.li`
  /* outline: 1px solid blue; */

  background-color: var(--cor-fundo-light-3);
  margin: var(--espacamento-extra-pequeno) 0;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  /* padding: var(--espacamento-pequeno); */

  /* Concluido */
  ${(props) => props.concluido >= 100
    && `border-left: 4px solid var(--cor-verde-300);`
  }

  /* Ativo */
  ${(props) => props.ativo && `
    order: -1;
  `}
  opacity: ${(props) => props.ativo ? '1' : '.5'};

  /* Trava sessão */
  ${(props) => props.travaSessao && `
    opacity: .5;
    box-shadow: unset;

    a {
      cursor: not-allowed;
      pointer-events: none;
    }
  `}

  a {
    display: flex;
    flex-direction: column;
    padding: var(--espacamento-pequeno);
    color: var(--cor-secundaria);
    text-decoration: none;

    h1 {
      margin-bottom: var(--espacamento-extra-pequeno);
      font-size: 14px;
    }

    span {
      color: var(--cor-texto-dark-0);
      font-size: 13px;
    }

    .subtitulo {
      opacity: .5;
    }

    .container {
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: space-between;
    }

    .somenteLeitura {
      margin-bottom: 10px;
      padding: 5px; 
      width: fit-content;
      font-size: 10px;
      color: #fff;
      border-radius: 3px;
      background-color: var(--cor-secundaria);
    }

    /* .somenteLeitura::before,
    .somenteLeitura::after {
      opacity: .5;
      content: ' ';
      display: inline-block;
      margin: 2px 5px;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: var(--cor-secundaria);
    } */

  }
`;

export const ListaVazia = styled.div`
  display: flex;
  flex-direction: column;

  padding: 70px var(--espacamento-pequeno) var(--espacamento-pequeno);

  text-align: center;
  text-decoration: none;
  color: var(--cor-secundaria);

  background-color: var(--cor-fundo-light-3);
`;

export const Span = styled.span`
  && {
    display: block;

    margin-top: 12px;
    text-align: left;
    color: var(--cor-texto-dark-alpha);
    font-weight: 500;
    ${props => (props.concluido > 0) && `
      color: var(--cor-primaria);
      font-weight: bold;
    `}

    ${props => (props.concluido >= 100) && `
      color: var(--cor-verde-300);
      font-weight: bold;
    `}

    @media (min-width: 600px) {
      text-align: left;
    }
  }
`;
