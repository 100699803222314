import styled from 'styled-components';
import {
  Radio,
  Checkbox,
  FormControlLabel,
  FormGroup
} from '@material-ui/core';

export const Container = styled(FormGroup)`
  && {
    margin-bottom: 90px;

    width: 100%;

  }
`;

export const Row = styled(FormControlLabel)`
  width: 100%;

  background: var(--cor-fundo-light-3);

  border-radius: 3px;

  &.MuiFormControlLabel-root {
    margin: 0 0 3px;
    background: rgba(255, 255, 255, 0.0);
  }

  .MuiTypography-body1 {
    width: 100%;
  }
`
export const Label = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 8px;
  padding-left: 0;

  font-family: var(--fonte-1);
  font-size: 14px;

  width: 100%;
`;

export const RadioButton = styled(Radio)`
  && {
    &.MuiRadio-root {
      color: var(--cor-preto);
      margin-bottom: auto;
    }

    &.Mui-checked {
      color: var(--cor-preto);
    }
  }
`;

export const CheckboxButton = styled(Checkbox)`
  && {
    &.MuiRadio-root {
      padding: 0 9px 0 0;

      margin-bottom: auto;
      color: var(--cor-preto);
    }

    &.Mui-checked {
      color: var(--cor-preto);
    }
  }
`;

export const AlternativaNaoCadastrada = styled.p`
  background: var(--cor-fundo-light-3);
  padding: 10px;
`;
