import React, { useEffect, memo } from 'react';

import { Toast } from './styles';

const Mensagem = ({ texto, tipo, exibicao, onChange, setIsLoading }) => {

  useEffect(() => {
    const exibition = setTimeout(() => {
      setIsLoading(false);
      onChange(false)
    }, 3000);

    return () => {
      clearTimeout(exibition)
    }
  }, [exibicao, onChange, setIsLoading])

  return (
    <Toast
      className={exibicao ? 'mostrar' : 'esconder'} 
      severity={tipo}
    >
			{texto}
    </Toast>
  );
};

export default memo(Mensagem);