import React, { useState, useEffect, memo } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import { Container } from './styles';

const CampoTexto = ({ valorInicial, resposta, onChange, ativo }) => {
  const [ texto, setTexto ] = useState('');

  useEffect(() => {
    setTexto(valorInicial || '');
  }, [valorInicial, resposta]);

  function handleChange(e) {
    let novoTexto = e.target.value;

    setTexto(novoTexto);
    onChange(novoTexto);
  }

  return (
    <Container ativo={ativo}>
      <TextareaAutosize
        value={texto}
        onChange={e => handleChange(e)}
        disabled={!ativo}
        minRows={4}
      />
    </Container>
  );
}

export default memo(CampoTexto);
