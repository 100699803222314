import React, { memo } from 'react';

import { IconButton } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { PerguntaContainer } from './styles';

const Pergunta = ({ pergunta, instrucao }) => {
  return (
    <>
      <PerguntaContainer>
        <p>{pergunta}</p>
        {instrucao &&
          <IconButton
            size="small"
            onClick={() => alert(instrucao)}>
            <InfoOutlined/>
          </IconButton>
        }
      </PerguntaContainer>
    </>
  );
}

export default memo(Pergunta)
