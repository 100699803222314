import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  padding: 4px;
  width: 100%;

  max-width: 600px;
  margin: 0 auto;

  h1 {
    color: var(--cor-secundaria);
    text-align: center;
  }

  button {
    color: var(--cor-secundaria);
    font-size: 13px;
  }
`;

export const Container = styled.div`
  position: fixed;
  top: 0;

  width: 100%;
  height: 56px;

  background-color: var(--cor-primaria);
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);

  z-index: 99999;
`;
