import React, { memo, useCallback } from 'react';

import { TextField } from '@material-ui/core';

import { MuiSelect, MuiList, MuitListItem } from './styles';

let concluido;

const Pessoas = ({ pessoas, selecionado, onChange, estaSalvando }) => {

  /**
   * Ao usuario selecionar uma opcao do autocomplete
   */
  const alteraPessoaSelecionada = useCallback((evento, valorSelecionado) => {
    let selecionado;
    pessoas.map((pessoa, index) => {
      if (pessoa.id_avaliado === valorSelecionado.id_avaliado) {
        selecionado = index;
      }
      return pessoas;
    })
    onChange(selecionado);
  }, [onChange, pessoas]);

  /**
   * Verifica se já foram respondidas as perguntas para os avaliados
   * @return - Array com objeto identificado quem já está concluido.
   */

  const pessoasConcluidasOuNao = pessoas
    .map(pessoa => {
      const res = pessoa.respostas.map(resposta => resposta.id_resposta !== null);
      return {
        concluido: !res.includes(false),
        nome: pessoa.nome
      }
  });

  return (
    <MuiSelect
      clearOnEscape
      disableClearable
      autoHighlight
      autoSelect
      disabled={estaSalvando}
      noOptionsText="Nenhuma Pessoa encontrada."
      options={pessoas}
      getOptionLabel={pessoa => `${pessoa.sigla ? `${pessoa.sigla.trim()} - ` : ''} ${pessoa.nome.trim()} ${pessoa.cidade ? `- ${pessoa.cidade.trim()}` : ''}`}
      onChange={alteraPessoaSelecionada}
      defaultValue={pessoas[selecionado]}
      ListboxComponent={props => {
        return (
          <MuiList {...props}>
            {props.children.map((item, index) => {
              pessoasConcluidasOuNao.map((pessoa, i) => {
                if (item.props.children.includes(pessoa.nome.trim())) {
                  concluido = pessoa.concluido;
                }
                return true;
              });

              return (
              <MuitListItem
                key={item.props.id}
                {...item.props}
                concluido={concluido}
              >
                {item.props.children}
              </MuitListItem>
            )})}
          </MuiList>
        )
      }}
      renderInput={params => (
        <TextField
          {...params}
          fullWidth
          label='Avaliados'
          autoComplete='off'
          variant='filled'
        />
      )}
    />
  );
}

export default memo(Pessoas);
