import React, { memo } from 'react';

import BotaoVoltar from '../../../componentes/BotaoVoltar/index.js'
import { useParams } from 'react-router-dom';

const Cabecalho = ({ titulo, location, schema }) => {

  const { id_formulario } = useParams();
  const evento = location.search;

  return (
    <BotaoVoltar
      location={`${schema}/formularios/${id_formulario}/sessoes${evento}`}
      titulo={titulo}
    />
  );
}

export default memo(Cabecalho);
