import React, { useRef, useEffect, memo, useCallback } from 'react';
import classNames from 'classnames';

import { Container, Wrapper, Row, Button } from './styles';
import { scrollToX } from '../../../../util/ferramentas';

const Navegacao = ({ disabled, perguntaAtual, totalPerguntas, onChange, avaliadoAtual, perguntas }) => {
  const $wrapper = useRef(null);
  const $row = useRef(null);

  const scrollMarcacoes = useCallback((navegacao) => {
    const $marcacoes = $row.current;
    const $wrapperCurrent = $wrapper.current;

    // Adiciona classes na container para usuario saber que existe rolagem
    if($marcacoes.scrollLeft > 0) $wrapperCurrent.classList.add('temScrollLeft');
    else $wrapperCurrent.classList.remove('temScrollLeft');

    if(($marcacoes.scrollLeft + $marcacoes.offsetWidth) < $marcacoes.scrollWidth) $wrapperCurrent.classList.add('temScrollRight');
    else $wrapperCurrent.classList.remove('temScrollRight');

    if(perguntaAtual && navegacao) {
      const $marcacaoAtual = $marcacoes.querySelector(
        `button:nth-child(${perguntaAtual + 1})`,
      )

      // Scroll Right
        // Verifica se a posição da marcação atual somada a largura da marcação atual menos a posição do scroll do
        // container é maior que a largura total do container. Com esse algoritmo nós conseguimos encontrar a marcação
        // que está posicionada à direita da tela e ao usuário clicar, ou chegar nessa marcação, realizamos um scroll
        // para a direita
      if (($marcacaoAtual.offsetLeft + $marcacaoAtual.offsetWidth - $marcacoes.scrollLeft) > $marcacoes.offsetWidth) {
        scrollToX($marcacoes, $marcacaoAtual.offsetLeft, 500)
      }
      // Scroll Left
        // Verifica se a posição do scroll do container é maior que a posição da marcação atual. Com esse algoritmo nós
        // conseguimos encontrar a marcação que está posicionada à esquerda da tela e ao usuário clicar, ou chegar nessa
        // marcação, realizamos um scroll para a esquerda
        else if ($marcacoes.scrollLeft > $marcacaoAtual.offsetLeft) {
        scrollToX($marcacoes, $marcacaoAtual.offsetLeft - ($marcacoes.offsetWidth - $marcacaoAtual.offsetWidth), 500)
      }
    }
  }, [perguntaAtual]);

  useEffect(() => {
    scrollMarcacoes('navegacaoAtravesDosBotoes')
  }, [scrollMarcacoes])

  function marcacoesRespondidas(id) {
    if(avaliadoAtual) {
      const respondido = avaliadoAtual.respostas.find(resposta => {
        // Verifica se existe resposta para pergunta
        return id === resposta.id_pergunta && resposta.id_resposta
      });

      return respondido !== undefined;
    }
  }

  return (
    <Container>
      <Wrapper ref={$wrapper}>
        <Row className={classNames({
            'maisQueCinco': totalPerguntas > 5,
            'disabled': disabled
          })}
          ref={$row}
          onScrollCapture={() => scrollMarcacoes()}
        >
          {perguntas && perguntas.map((pergunta, index) => (
            <Button
              key={index}
              className={classNames({
                'atual': perguntaAtual === index,
                'respondido': marcacoesRespondidas(pergunta.id_pergunta)
              })}
              onClick={() => onChange(index)}
            >{index+1}</Button>
          ))}
        </Row>
      </Wrapper>
    </Container>
  )
}

export default memo(Navegacao);
