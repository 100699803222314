import React from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';

export const Container = styled.div`
  && {
    display: flex;
    justify-content: center;

    padding: 20vh;
    box-sizing: border-box;

    width: 100vw;
    height: 100vh;

    background: var(--cor-fundo-opaco);

    .MuiCircularProgress-colorPrimary {
      margin-top: 50px;
    }
  }
`;

const Spinner = () => {
    return (
      <Container>
        <CircularProgress color="primary"/>
      </Container>
    );
}

export default Spinner;
