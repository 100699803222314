import styled from 'styled-components';

export const PerguntaContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: var(--espacamento-pequeno);
  padding: 8px;

  border-radius: 3px;

  background-color: var(--cor-preto);

  line-height: 120%;

  p {
    color: var(--cor-branco);

    font-size: 16px;
    font-weight: 500;
  }

  span {
    color: var(--cor-branco);
  }
`;
