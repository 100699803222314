import React, { useState, useEffect, useCallback, memo } from 'react';

import {
  IconButton
} from '@material-ui/core';

import { InfoOutlined } from '@material-ui/icons';

import { Container, Row, Label, CheckboxButton } from '../ListaRadio/styles';

let listaTemporaria = {};
let listaAlternativas = {};

const ListaCheckbox = ({
  items,
  valorInicial,
  propriedadeLabel,
  propriedadeValor,
  propriedadeDica,
  onChange,
  avaliadoAtual,
  ativo,
  }) => {

  const [ listaChecados, setListaChecados] = useState();

  // Função para tratar dados do Input
  const manipulaEntradaDeDados = useCallback((valorInicial) => {
    // Faz um map e retorna um array com os id_alternativas
    let listaAlternativasDaPergunta = items.map(item => (item.id_alternativa));

    // Transforma a lista de alternativas em um objeto onde os ids compõem a chave do objeto
    // e o valor delas é falso
    let transformaListaAlternativasEmObjeto = listaAlternativasDaPergunta.reduce((obj, item) => ((obj[item] = false, obj)), {});

    // Input: Transforma o valorInicial (Que é um array de id_alternativas) em um objeto
    // onde os valores compõem a chave do objeto e o valor delas é true para saber
    // que a resposta está marcada. Ex: {139: true, 140: true}
    let transformaValorInicialEmObjeto = valorInicial && valorInicial.reduce((obj, item) => ((obj[item] = true, obj)),{});

    // Une os dois objetos, sobrescrevendo os valores (falso ou true) para saber se a checkbox está checada ou não
    return listaAlternativas = { ...transformaListaAlternativasEmObjeto, ...transformaValorInicialEmObjeto }
  }, [items]);

  useEffect(() => {
    manipulaEntradaDeDados(valorInicial);
    setListaChecados(listaAlternativas);
  }, [valorInicial, manipulaEntradaDeDados, avaliadoAtual]);


  function handleCheckButton(e) {
    // Atualiza a listaChecados atual com o valor true ou false para o id_alternativa
    setListaChecados({...listaChecados, [e.target.name] : e.target.checked });
    // Cria uma listaTemporaria para não perder os valores durante a renderização do componente
    listaTemporaria = { ...listaChecados, [e.target.name] : e.target.checked };

    // Trata os dados recebidos para enviar para a API
    const checados = verificaSeEstaChecado(listaTemporaria);
    onChange(checados);
  }

  function verificaSeEstaChecado(lista) {
    // Output: Transforma o objeto de itens checados em um array,
    // filtrando apenas aqueles que possuem o valor "true"
    const listaDeChecados = Object.keys(lista).filter(key => {
      let value = lista[key];

      if(value === true)
        return parseInt(key);

      return false;
    })

    // O método Filter retorna um array com o valor, por isso foi necessário
    // percorrer ele para retornar apenas o valor em forma de inteiro
    return listaDeChecados.map(checado => parseInt(checado))
  }

  return (
    <Container>
      {listaChecados && items && items.map((item, index) => (
        <Row
          key={index}
          checked={listaChecados[item[propriedadeValor]] || false}
          control={
            <CheckboxButton
              name={`${item[propriedadeValor]}`}
              value={item[propriedadeValor] || false}
              checked={listaChecados[item[propriedadeValor]] || false}
              onChange={e => handleCheckButton(e)}
              disabled={!ativo}
            />
          }
          label={
            <Label>
              {item[propriedadeLabel]}
              {item[propriedadeDica] &&
                <IconButton
                  size="small"
                  onClick={() => alert(item[propriedadeDica])}>
                  <InfoOutlined/>
                </IconButton>
              }
            </Label>
          }
        />
      ))}
    </Container>
  );
}

export default memo(ListaCheckbox);
