import React, { useState, useEffect, memo } from 'react';

import { IconButton } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';

import { Container, Row, Label, RadioButton, AlternativaNaoCadastrada } from './styles';

const ListaRadio = ({
  items,
  valorInicial,
  propriedadeLabel,
  propriedadeValor,
  propriedadeDica,
  resposta,
  onChange,
  ativo,
 }) => {

  const [ checado, setChecado ] = useState([valorInicial]);

  useEffect(() => {
    setChecado(valorInicial);
  }, [valorInicial, resposta]);

  function handleRadioButton(e) {
    setChecado(e);
    onChange([parseInt(e)]);
  }

  return (
    <Container>
    {items && items.map((item, index) => {
      if (item.id_alternativa === null) {
        return (
          <AlternativaNaoCadastrada>Alternativa não cadastrada!</AlternativaNaoCadastrada>
        )
      }
      return (
        <Row
          key={index}
          checked={parseInt(checado) === parseInt(item[propriedadeValor])}
          control={
            <RadioButton
              name={`${item[propriedadeValor]}`}
              value={item[propriedadeValor]}
              checked={parseInt(checado) === parseInt(item[propriedadeValor])}
              onChange={e => handleRadioButton(e.currentTarget.value)}
              disabled={!ativo}
            />
          }
          label={
            <Label color="primary" checked={parseInt(checado) === parseInt(item[propriedadeValor])}>
              {item[propriedadeLabel]}
              {item[propriedadeDica] &&
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => alert(item[propriedadeDica])}>
                  <InfoOutlined/>
                </IconButton>
              }
            </Label>
          }
        />
      )
    })}
  </Container>
  );
}

export default memo(ListaRadio);
