import styled from "styled-components";

import '../../util/cssGlobal/configuracao/cores.css';
import '../../util/cssGlobal/configuracao/espacamento.css';

import { Container } from "@material-ui/core";

export const ContainerLogin = styled(Container)`
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: var(--espacamento-extra-grande);

    header {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 140px;
        margin-bottom: var(--espacamento-grande);
      }
    }

    form {
      display: flex;
      flex-direction: column;
      max-width: 400px;
      margin: 0 auto;
      padding: var(--espacamento-pequeno);
      width: 100%;

      margin: 0 auto;
      padding: var(--espacamento-pequeno);

      div {
        margin-bottom: var(--espacamento-pequeno);

        label,
        svg {
          color: var(--cor-secundaria);
        }
      }

      .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
        display: none;
      }

      button.MuiButton-containedPrimary:hover {
        background-color: var(--cor-secundaria);
        color: var(--cor-branco);
      }

    }
    .MuiPickersBasePicker-pickerView {
      min-width: auto;
    }
`;
