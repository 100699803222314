import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import ContextProvider from './context'
import Rotas from './rotas';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#d8b569',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    secondary: {
      light: 'rgba(0, 0, 0, 0.87)',
      main: '#000000',
      contrastText: '#ffF',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ContextProvider>
        <Rotas />
      </ContextProvider>
    </ThemeProvider>
  );
}

export default App;
