import styled from 'styled-components';

import Alert from '@material-ui/lab/Alert';

export const Toast = styled(Alert)`
  && {
    opacity: 0;
    display: flex;
    visibility: hidden;
    justify-content: center;
    
    position: fixed;
    z-index: 999;
    bottom: 15px;
    left: 50%;
    
    
    margin: 0 auto;
    padding: 5px 15px;
    height: auto;
    min-height: 36px;

    font-weight: bold;
    color: #fff;
    text-align: center;
      
    transform: translate(-50%, 20px);
    transition: all 200ms linear;

    box-shadow: 1px 1px 15px rgb(0 0 0 / 30%);
  }
  
  &.MuiAlert-standardSuccess {
    background: var(--cor-verde-400);
  }

  &.MuiAlert-standardSuccess .MuiAlert-icon {
    color: #fff;
  }
  
  &.MuiAlert-standardError {
    background: var(--cor-vermelho-100);
  }

  &.MuiAlert-standardError .MuiAlert-icon {
    color: #fff;
  }

  &.mostrar {
    opacity: 1;
    visibility: visible;
    
    transform: translate(-50%, 0);
  }
`;