export const TOKEN_KEY = "@sima-Token";
export const isAuthenticated = () => window.localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => window.localStorage.getItem(TOKEN_KEY);
export const login = token => {
    if(token === undefined) {
        return
    }
    window.localStorage.setItem(TOKEN_KEY, token);
};
export const logout = () => {
    window.localStorage.removeItem(TOKEN_KEY);
};