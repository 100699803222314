import React, { useContext, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { Context } from '../../context';

import Spinner from '../Spinner/index';

import { Cards, CardItem, ListaVazia, BarraProgresso, Porcentagem } from './styles';


const Lista = ({ items, url, listaVazia, progresso }) => {
  const { isLoading } = useContext(Context);

  const getItemProgress = useCallback((progresso) => {

    if (progresso >= 100) {
      return 100;
    }
    return progresso;
  }, []);

  if (isLoading)
    return <Spinner />

  return (
    <>
      {items && items.length ?
        <Cards>
          {items.map((item, index) => (
            <CardItem
              key={index}
              concluido={item.percentual_concluido}
              travaSessao={item.travaSessao}
              ativo={item.ativo}
            >
              <Link to={item[`${url}`]}>

                {/* Read only */}
                {
                  !item.ativo &&
                  <span className="somenteLeitura">
                    Somente Leitura
                  </span>
                }

                {/* Container two columns */}
                <div className="container">
                  {
                    item.titulo &&
                    <div className="titulo">
                      <h1>{item.titulo}</h1>
                      {
                        item.subtitulo &&
                        <h1 className="subtitulo">
                          {
                            item.subtitulo.split(' - ')
                              .map(item => <>{item}<br /></>)
                          }
                        </h1>}
                    </div>
                  }

                  {/* Progress text */}
                  {
                    progresso &&
                    <Porcentagem value={getItemProgress(item.percentual_concluido)}>
                      {getItemProgress(item.percentual_concluido)}% preenchido
                    </Porcentagem>
                  }
                </div>

                {/* Progress bar */}
                {
                  progresso &&
                  <BarraProgresso variant="determinate" value={getItemProgress(item.percentual_concluido)} />
                }
              </Link>
            </CardItem>
          ))}
        </Cards>
        :
        <ListaVazia>
          {listaVazia} {/*TODO: Colocar link para o Infogab */}
        </ListaVazia>}
    </>
  );
}

export default Lista;
