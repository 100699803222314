import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import { ArrowBack } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { Container, Wrapper } from './styles';

const BotaoVoltar = ({ texto = <ArrowBack/>, location, titulo }) => {

  return (
    <Container>
      <Wrapper>
        <Link to={`/${location}`}>
          <IconButton>
            {texto}
          </IconButton>
        </Link>
        {titulo && <h1>{titulo}</h1>}
      </Wrapper>
    </Container>
  );
}

export default memo(BotaoVoltar);
