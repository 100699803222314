import React, {
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback } from 'react';

import { useParams } from 'react-router-dom';

import { Context } from '../../context';
import { post, put } from '../../servicos/api';

import Cabecalho from './Cabecalho';
import Mensagem from '../../componentes/Mensagem';
import Navegacao from './Pergunta/Navegacao';
import Pessoas from './Pessoas';
import Pergunta from './Pergunta';
import Resposta from './Resposta';
import Spinner from '../../componentes/Spinner';

import { MuiContainer } from './styles';
import { ListaVazia } from '../../componentes/Lista/styles';

let ultimaRespostaParaSalvar, corpo;

const Avaliacao = ({ location }) => {
  let { id_sessao, id_formulario } = useParams();
  let evento = location.search;

  const {
    getLista,
    avaliacoes,
    setAvaliacoes,
    isLoading,
    setIsLoading,
    sessoes,
    error,
    schema
  } = useContext(Context);

  const [ avaliacaoAtual, setAvaliacaoAtual ] = useState(0);
  const [ avaliadoAtual, setAvaliadoAtual ] = useState(0);
  const [ respostaAtual, setRespostaAtual ] = useState(0);
  const [ estaSalvando, setEstaSalvando ] = useState(false);
  const [ salvo, setSalvo ] = useState(false);

  const avaliados = avaliacoes.avaliados;
  const perguntas = avaliacoes.perguntas;

  // Chamadas Api
  useEffect(() => {
    getLista({lista: 'avaliacoes', id_formulario, id_sessao, evento});
    setAvaliacaoAtual(0);
  }, [getLista, id_sessao, id_formulario, evento]);

  // Funções internas
  const obtemSessaoAtual = useMemo(() => {
    let sessaoAtual = sessoes?.filter(sessao => {
      if(sessao.id_sessao === parseInt(id_sessao))
        return sessao;

      return null;
    });

    return sessaoAtual;
  }, [sessoes, id_sessao]);

  const obtemTituloSessaoAtual = useMemo(() => {
    if(obtemSessaoAtual) {
      const sessaoAtual = obtemSessaoAtual;
      return sessaoAtual.length ? sessaoAtual[0].titulo : 'Carregando...';
    } else {
      return 'Sessão cadastrada sem título'
    }
  }, [obtemSessaoAtual]);

  const salvarAvaliacao = useCallback(async (resposta) => {
    let response;
    let avaliadosTemp = avaliados;

    const avaliacao = avaliados[avaliadoAtual].respostas[respostaAtual];
    const pergunta = perguntas[avaliacaoAtual];

    corpo = {
      id_pergunta: avaliacao.id_pergunta,
      id_avaliado: avaliacao.id_avaliado,
      id_avaliador: avaliacao.id_avaliador,
      id_evento: avaliacao.id_evento,
      id_alternativa: null,
      descricao: null,
    };

    if(pergunta.tags) {
      corpo.idTags = pergunta.tags;
    }

    if(pergunta.tipo === 'TEXT')
      corpo.descricao = resposta;
    else if(pergunta.tipo === 'RADIO' || pergunta.tipo === 'CHECKBOX')
      corpo.id_alternativa = resposta;

    if(estaSalvando) {
      ultimaRespostaParaSalvar = resposta;
      return;
    }

    if(!avaliacao.id_resposta && !resposta)
      return;

    try {
      setEstaSalvando(true);
      setSalvo(false);

      if(avaliacao.id_resposta) {
        response = await put(`/formularios/${id_formulario}/sessoes/${id_sessao}/avaliacoes/${avaliacao.id_resposta}`, corpo);
      } else {
        response = await post(`/formularios/${id_formulario}/sessoes/${id_sessao}/avaliacoes`, corpo);
      }

      if(response)
        avaliados[avaliadoAtual].respostas[respostaAtual].id_resposta = response.id_resposta;

      setAvaliacoes({ ...avaliacoes, avaliados });
      setEstaSalvando(false);

      if(ultimaRespostaParaSalvar || ultimaRespostaParaSalvar === '') {
        salvarAvaliacao(ultimaRespostaParaSalvar);
        ultimaRespostaParaSalvar = null;
      }
      else {
        if (!response.id_resposta)
          corpo.descricao = ''
        else
          corpo.id_resposta = response?.id_resposta;

        avaliados[avaliadoAtual].respostas[respostaAtual] = corpo;
        setAvaliacoes({ ...avaliacoes, avaliados });
        setSalvo(true);
      }
    } catch (error) {
      if(error.code === '422.001'){
        avaliadosTemp[avaliadoAtual].respostas[respostaAtual].descricao = '';
        setAvaliacoes({ ...avaliacoes, avaliadosTemp });
        setEstaSalvando(false);
      }
    }
  }, [avaliacaoAtual, avaliacoes, avaliadoAtual, avaliados, estaSalvando, id_formulario, id_sessao, perguntas, respostaAtual, setAvaliacoes]);

  // Funções internas
  const irParaPergunta = useCallback((destino) => {
    if (destino === 'ANTERIOR' && avaliacaoAtual > 0)
      setAvaliacaoAtual(avaliacaoAtual - 1);

    else if (destino === 'PROXIMO' && (avaliacaoAtual + 1 < perguntas?.length))
      setAvaliacaoAtual(avaliacaoAtual + 1);

    else if (destino >= 0 && destino < perguntas?.length)
      setAvaliacaoAtual(destino);
  },[avaliacaoAtual, perguntas]);

  const obtemResposta = useMemo(() => {
    const respostas = avaliados && avaliados[avaliadoAtual]?.respostas;

    let respostaEncontrada = respostas?.filter((resposta, index) => {
      if((avaliados[avaliadoAtual].id_avaliado === resposta.id_avaliado) &&
          (perguntas[avaliacaoAtual].id_pergunta === resposta.id_pergunta))
            {
              setRespostaAtual(index);
              return resposta;
            }
      return null;
    });

    return respostaEncontrada;
  }, [avaliacaoAtual, avaliadoAtual, avaliados, perguntas]);

  return (
    <>
      {/* Cabeçalho
      -----------------------------------------------------*/}
      <Cabecalho
        titulo={obtemTituloSessaoAtual}
        location={location}
        schema={schema}
      />

      {/* Carregando
      -----------------------------------------------------*/}
      {isLoading && <Spinner />}

      {/* Avaliação
      -----------------------------------------------------*/}
      {!isLoading && perguntas && perguntas.length ?
        <MuiContainer>
          <Mensagem
            texto="Salvo!"
            tipo="success"
            exibicao={salvo}
            onChange={setSalvo}
            setIsLoading={setIsLoading}
          />
          {avaliados && avaliados.length && avaliados[0].id_avaliado !== avaliados[0].id_avaliador ? (
            <Pessoas
              pessoas={avaliados}
              selecionado={avaliadoAtual}
              onChange={setAvaliadoAtual}
              estaSalvando={estaSalvando}
            />
          ) : ''}
          <Navegacao
            disabled={estaSalvando}
            totalPerguntas={perguntas.length}
            perguntas={perguntas}
            perguntaAtual={avaliacaoAtual}
            avaliadoAtual={avaliados[avaliadoAtual]}
            onChange={irParaPergunta}
          />
          <Pergunta
            pergunta={perguntas[avaliacaoAtual].pergunta}
            instrucao={perguntas[avaliacaoAtual].dica}
          />
          <Resposta
            tipo={perguntas[avaliacaoAtual].tipo}
            alternativas={perguntas[avaliacaoAtual].alternativas}
            resposta={obtemResposta}
            avaliadoAtual={avaliadoAtual}
            salvarAvaliacao={salvarAvaliacao}
            sessao={obtemSessaoAtual}
          />
        </MuiContainer>
      :
        <>
          {/* Vazio
          -----------------------------------------------------*/}
          {(!isLoading && (!avaliados || !perguntas)) &&
            <ListaVazia>{error}</ListaVazia>}
        </>
      }
    </>
  );
}

export default Avaliacao;
