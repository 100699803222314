import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  max-width: 600px;
  margin: 20px auto 15px;
`;

export const Wrapper = styled.div`
  position: relative;

  &:before,
  &:after {
    content: '';
    display: block;
    width: 15px;
    height: 100%;

    position: absolute;
    top: -6px;
    pointer-events: none;
    z-index: 2;
  }

  &.temScrollLeft::before {
    content: '';
    left: 0;

    background: linear-gradient(to left, transparent, var(--cor-fundo-cinza));
  }

  &.temScrollRight::after {
    content: '';
    right: 0;

    background: linear-gradient(to right, transparent, var(--cor-fundo-cinza));
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;

  height: 50px;
  padding-bottom: 10px;

  overflow-x: auto;

  position: relative;

  @media(max-width: 600px) {
    &.maisQueCinco {
      justify-content: flex-start
    }
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

const pulse = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
`;

export const Button = styled.button`
  margin: 4px;

  width: 100%;
  max-width: 75px;
  min-width: 35px;
  min-height: 20px;

  border-radius: 4px;
  background-color: var(--cor-fundo-light-3);
  font-weight: bold;

  outline: none;

  position: relative;
  opacity: 0.6;
  border: 0;

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }

  &.respondido {
    background-color: var(--cor-verde-300);
    color: var(--cor-branco);
  }

  &.atual {
    opacity: 1;
  }

  &.atual:after {
    content: '';
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: black;
    position: absolute;
    bottom: -10px;
    left: calc(50% - 10px);
    animation: 3s ${pulse} infinite;
  }
`;
