import styled from "styled-components";

import { Autocomplete } from '@material-ui/lab';

export const MuiList = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const MuitListItem = styled.li`
  && {
    font-weight: bold;
    line-height: 1.2;

    margin-bottom: 4px;
    ${props => props.concluido ? `
      color: var(--cor-verde-300);
      border-left: 4px solid var(--cor-verde-300);
    ` : `
      background-color: var(--cor-fundo-light-1);
      order: -1;
    `}
  }
`;

export const MuiSelect = styled(Autocomplete)`
  && {
    display: flex;
    justify-content: center;

    margin: var(--espacamento-medio) 0;
    padding: 0;

    border-radius: 3px;

    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);

    .MuiTextField-root .MuiInputBase-root {
      background: var(--cor-fundo-light-3);
    }

    &.MuiInput-underline:after {
      border-bottom-color: var(--cor-secundaria);
    }
  }
`;
