import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    margin-bottom: 100px;

    textarea {
        margin: 0px;
        padding: 15px;

        color: var(--cor-secundaria);
        background: var(--cor-fundo-light-3);
        font-size: 14px;
        border-radius: 3px;

        width: 100%;
        height: 120px;

        resize: none;
        opacity: ${(props) => props.ativo ? '1' : '0.6'}
    }
`;
